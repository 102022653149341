<template>
    <!-- Location -->
    <section id="location">
        <div :class="'section-content section-content-xs style-' + sectionStyle">
            <div class="container">
                <!-- Title -->
                <div class="row mb-4">
                    <div class="col-12 text-left">
                        <p class="h1 styled-header font-weight-bold mb-3">Где нас найти?</p>
                    </div>
                    <div class="col-12 text-left">
                        <p class="h5 color-basic-text mb-4">Адрес: Самара, ул. Красноармейская 1, 2 этаж, бутик 231</p>
                    </div>
                </div>
                <!-- Map -->
                <div class="row">
                    <div class="col-12 mb-4">
                        <yandex-map :settings="{apiKey:'63a4a539-2833-4f4e-b261-a4f8b2f034aa'}" :coords="[53.19481856784408,50.090641650802354]" :zoom="15" style="height:640px; border-radius:32px">
                            <ymap-marker 
                                :coords="[53.19481856784408,50.090641650802354]" 
                                marker-id="1" 
                                hint-content="2 Этаж, Бутик 231" 
                            />
                        </yandex-map>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { yandexMap, ymapMarker } from 'vue-yandex-maps'

export default {
    name: 'LocationSection',
    components: {
        yandexMap, ymapMarker
    },
    props: {
        sectionStyle: {
            type: String,
            default: 'basic'
        }
    }
}
</script>
