<template>
    <div id="contacts">
        <!-- Main Contacts -->
        <section id="main-contacts">
            <div class="section-content section-content-xs style-basic">
                <div class="container">
                    <!-- Title -->
                    <div class="row">
                        <div class="col-12 text-center text-md-left">
                            <p class="h1 styled-header font-weight-bold mb-4">Контакты</p>
                        </div>
                    </div>
                    <!-- Contacts -->
                    <div class="row">
                        <!-- Email -->
                        <div class="col-12 col-md-6 col-lg-4 mb-4">
                            <a href="mailto:domus731@yandex.ru" class="card card-contact border-md border-transparent border-secondary-active$ md-up$">
                                <div class="card-header style-basic">
                                    <i class="card-icon fa fa-envelope style-colored"></i>
                                    <p class="card-title">
                                        Эл. почта
                                    </p>
                                </div>
                                <div class="card-body p-0">
                                    <div class="card-text">
                                        Нажмите чтобы написать.
                                        domus731@yandex.ru
                                    </div>
                                </div>
                            </a>
                        </div>
                        <!-- Phone -->
                        <div class="col-12 col-md-6 col-lg-4 mb-4">
                            <a href="tel:+79063920511" class="card card-contact border-md border-transparent border-secondary-active$ md-up$">
                                <div class="card-header style-basic">
                                    <i class="card-icon fa fa-phone style-colored"></i>
                                    <p class="card-title">
                                        Телефон
                                    </p>
                                </div>
                                <div class="card-body p-0">
                                    <div class="card-text">
                                        Нажмите чтобы позвонить.
                                        +7 (906) 392-05-11
                                    </div>
                                </div>
                            </a>
                        </div>
                        <!-- Address -->
                        <div class="col-12 col-md-6 col-lg-4 mb-4">
                            <a href="#location" class="card card-contact border-md border-transparent border-secondary-active$ md-up$">
                                <div class="card-header style-basic">
                                    <i class="card-icon fa fa-map-marker style-colored"></i>
                                    <p class="card-title">
                                        Адрес
                                    </p>
                                </div>
                                <div class="card-body p-0">
                                    <div class="card-text">
                                        Самара, ул. Красноармейская 1, 2 этаж, бутик 231
                                    </div>
                                </div>
                            </a>
                        </div>

                        <!-- WhatsApp -->
                        <div class="col-12 col-md-6 col-lg-4 mb-4">
                            <a target="_blank" href="https://api.whatsapp.com/send?phone=79063920511&text=%D0%97%D0%B4%D1%80%D0%B0%D0%B2%D1%81%D1%82%D0%B2%D1%83%D0%B9%D1%82%D0%B5,%20%D0%BD%D1%83%D0%B6%D0%BD%D0%B0%20%D0%BA%D0%BE%D0%BD%D1%81%D1%83%D0%BB%D1%8C%D1%82%D0%B0%D1%86%D0%B8%D1%8F." class="card card-contact border-md border-transparent border-secondary-active$ md-up$">
                                <div class="card-header style-basic">
                                    <i class="card-icon fa fa-whatsapp style-colored"></i>
                                    <p class="card-title">
                                        WhatsApp
                                    </p>
                                </div>
                                <div class="card-body p-0">
                                    <div class="card-text">
                                        Нажмите чтобы написать.
                                    </div>
                                </div>
                            </a>
                        </div>

                        <!-- Viber -->
                        <div class="col-12 col-md-6 col-lg-4 mb-4">
                            <a href="viber:://chat?number=%2B79063920511" class="card card-contact border-md border-transparent border-secondary-active$ md-up$">
                                <div class="card-header style-basic">
                                    <i class="card-icon fab fa-viber style-colored"></i>
                                    <p class="card-title">
                                        Viber
                                    </p>
                                </div>
                                <div class="card-body p-0">
                                    <div class="card-text">
                                        Нажмите чтобы написать.
                                    </div>
                                </div>
                            </a>
                        </div>

                        <!-- Telegram -->
                        <!--div class="col-12 col-md-6 col-lg-4 mb-4">
                            <a target="_blank" href="https://t.me/DomusDecor" class="card card-contact border-md border-transparent border-secondary-active$ md-up$">
                                <div class="card-header style-basic">
                                    <i class="card-icon fa fa-telegram style-colored"></i>
                                    <p class="card-title">
                                        Telegram
                                    </p>
                                </div>
                                <div class="card-body p-0">
                                    <div class="card-text">
                                        Нажмите чтобы написать.
                                    </div>
                                </div>
                            </a>
                        </div-->

                        <!-- Instagram -->
                        <div class="col-12 col-md-6 col-lg-4 mb-4">
                            <a target="_blank" href="https://www.instagram.com/decor_domus" class="card card-contact border-md border-transparent border-secondary-active$ md-up$">
                                <div class="card-header style-basic">
                                    <i class="card-icon fa fa-instagram style-colored"></i>
                                    <p class="card-title">
                                        Instagram
                                    </p>
                                </div>
                                <div class="card-body p-0">
                                    <div class="card-text">
                                        Нажмите чтобы открыть.
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <LocationSection section-style="secondary-basic"/>

        <!-- Credentials -->
        <section id="main-contacts">
            <div class="section-content section-content-xs style-basic">
                <div class="container">
                    <!-- Title -->
                    <div class="row">
                        <div class="col-12 text-center text-md-left">
                            <p class="h1 styled-header font-weight-bold mb-4">Реквизиты</p>
                        </div>
                    </div>
                    <!-- Cards -->
                    <div class="row">
                        <div class="col-12">
                            <p class="h5 color-basic-text">
                                ООО "ДОМУС" <br>
                                ИНН: 7329013060 <br>
                                КПП: 732901001 <br>
                                ОГРН: 1137329004163 <br>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import LocationSection from '../components/blocks/LocationSection.vue'

export default {
    name: 'Contacts',
    components: {
        LocationSection
    }
}
</script>